<template>
  <div
    class="smart-triple-select__wrapper"
    :class="{ 'smart-triple-select__wrapper--5g': is5g }"
    :style="{
      'background-image': imagePath
    }"
  >
    <div class="smart-triple-select__title--reverse">
      <h3 class="title smart-triple-select__title">
        Allnet Flat +
        <span class="no-break">
          Datenvolumen
          <Footnote
            class="smart-triple-select__footnote"
            :footnote-id="tariff.footnoteId"
          />
        </span>
      </h3>
      <h4 class="subtitle smart-triple-select__title">
        <strong>Die Smart-Tarife:</strong> Datenvolumen wählen
      </h4>
    </div>
    <div class="smart-triple-select__content">
      <div class="smart-triple-select__group-slider">
        <div class="smart-triple-select__text">
          <TripleSelect
            class="smart-triple-select__picker"
            :value="current"
            :labels="displayText"
            @input="switchTariff"
          />

          <span class="ribbon">Die ersten 4 Wochen kostenlos!</span>

          <Checklist class="smart-triple-select__list">
            <li>{{ tariff.includedData }} GB Highspeed-Datenvolumen</li>
            <li>Allnet-Flat für Telefonie &amp; SMS</li>
            <li>Inklusive EU-Roaming</li>
          </Checklist>
        </div>
      </div>

      <div class="smart-triple-select__group-btn">
        <h5 data-cy="smart-triple-select-type" class="subtitle">
          Smart {{ tariff.size }}
        </h5>
        <div style="display: flex">
          <Price
            class="smart-triple-select__price"
            :price="tariff.price"
            period="4 Wochen"
          />
          <Footnote
            class="smart-triple-select__footnote"
            :footnote-id="tariff.footnoteId"
          />
        </div>

        <ButtonComponent
          data-cy="smart-triple-select-button"
          class="smart-triple-select__button"
          :clickhandler="() => $router.push(path)"
          :href="$router.resolve(path).href"
          >Zum Tarif</ButtonComponent
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Checklist from 'src/components/common/checklist/checklist.vue'
import TripleSelect from 'src/components/common/input/triple_select.vue'

export default {
  name: 'PortalHomeAllnet',
  components: {
    Price: defineAsyncComponent(() =>
      import('src/components/common/pack/components/price.vue')
    ),
    Footnote: defineAsyncComponent(() =>
      import('src/components/common/footnote/footnote.vue')
    ),
    TripleSelect,
    Checklist,
    ButtonComponent
  },
  data() {
    return {
      current: 0,
      tariffs: [
        {
          slug: 'smart-s',
          includedData: 10,
          price: 9.99,
          footnoteId: this.$brand.isFonic() ? 3 : 38,
          size: 'S'
        },
        {
          slug: 'smart-m',
          includedData: 20,
          price: 14.99,
          footnoteId: this.$brand.isFonic() ? 25 : 36,
          size: 'M'
        },
        {
          slug: 'smart-l',
          includedData: 30,
          price: 19.99,
          footnoteId: this.$brand.isFonic() ? 5 : 37,
          size: 'L'
        }
      ],
      is5g: false
    }
  },
  computed: {
    tariff() {
      return this.tariffs[this.current]
    },
    imagePath() {
      return this.is5g
        ? `url(/assets/webp_images/portal/tariffs/${this.$brand.code}-5g-badge.webp)`
        : `url(/assets/webp_images/portal/home/${this.$brand.code}-${this.tariff.slug}.webp)`
    },
    displayText() {
      let texts = this.tariffs.map(elem => elem.includedData + ' GB')

      return texts
    },
    path() {
      return {
        name: 'PortalTariffDetails',
        params: { slug: `${this.$brand.code}-${this.tariff.slug}` }
      }
    }
  },
  methods: {
    switchTariff(index) {
      this.current = index
    }
  }
}
</script>

<style lang="scss" scoped>
.smart-triple-select__wrapper {
  @include font-normal;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary);
  border-radius: 8px;
  margin: 8px 0;
  padding: 20px 16px;
  background-position: top right;

  .smart-triple-select__title {
    max-width: 60%;

    @include fonic-mobile {
      max-width: 65%;
    }
  }

  .subtitle {
    line-height: 1.2;
    margin-bottom: 10px;
    min-width: 152px;

    strong {
      @include font-bold;
    }
  }

  .title {
    min-width: 225px;
    margin-bottom: 30px;

    .smart-triple-select__footnote {
      font-size: 75%;

      :deep(.super-footnote) {
        margin-left: 2px;
        top: -10px;
      }
    }
  }

  @include breakpoint($from-tablet) {
    max-width: 50%;
    margin: 8px;
  }

  @include breakpoint($from-desktop) {
    max-width: 100%;
    flex-grow: 2;
    margin-right: 0;
  }

  @include breakpoint($up-to-desktop) {
    background-size: 40%;

    @include fonic-mobile {
      background-size: 50%;
    }

    .title {
      margin-bottom: 15px;
    }
  }
}

.smart-triple-select__wrapper--5g {
  background-size: 150px;
  background-origin: content-box;

  @include breakpoint($up-to-desktop) {
    background-size: 30%;
  }

  @include breakpoint($up-to-tablet) {
    background-size: 25%;
  }
}

.smart-triple-select__content {
  display: flex;
  flex-grow: 1;

  .smart-triple-select__group-slider {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .smart-triple-select__text {
      display: flex;
      flex-direction: column;
    }
  }

  @include breakpoint($up-to-desktop) {
    flex-direction: column;
  }
}

.smart-triple-select__picker {
  flex-grow: 1;
  margin-bottom: 24px;

  @include breakpoint($from-desktop) {
    margin-right: 15px;
  }
}

.smart-triple-select__list {
  margin: 10px 0 15px;
  padding-left: 11px;

  @include breakpoint($from-desktop) {
    margin: 10px 0 17px;

    :deep(li) {
      padding-top: 16px;
    }
  }
}

.smart-triple-select__group-btn {
  min-width: 245px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .subtitle {
    margin: 5px 0;
  }
}

.smart-triple-select__button {
  margin-top: calc-rem(13px);
}

.smart-triple-select__price {
  align-self: flex-start;
  margin-left: 0;
}

.ribbon {
  color: $c_night-blue;
  padding: 6px 10px 4px;
  border-radius: 16px;
  background: $ribbon-color;
  align-self: flex-start;

  @include breakpoint($up-to-tablet) {
    padding: 12.5px 25px;
    border-radius: 0 25px 25px 0;
    align-self: flex-start;
    margin-left: -16px;
  }
}

.smart-triple-select__title--reverse {
  display: flex;
  flex-direction: column-reverse;
}
</style>
