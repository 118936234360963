<template>
  <div class="triple--wrapper">
    <span :id="groupLableId" class="visually-hidden" aria-hidden="true">
      {{ `${$brand.name} Smart Tarif auswählen:` }}
    </span>

    <div
      class="marker--wrapper"
      :style="{ height: hasValidLabels ? '93px' : '43px' }"
    >
      <div v-if="hasValidLabels">
        <div
          v-for="(label, index) in labels"
          :ref="`marker${index}`"
          :key="`text-${index}`"
          class="marker"
          :class="{ rise: selected === index, 'marker-animation': animate }"
        >
          <div class="marker--display" :class="{ 'marker-animation': animate }">
            <label :id="getLabelId(index)" class="marker--text">{{
              label
            }}</label>
          </div>

          <div class="needle" />
        </div>
      </div>

      <div
        ref="container"
        class="selector--container"
        role="radiogroup"
        :aria-labelledby="groupLableId"
      >
        <ButtonComponent
          v-for="(state, index) in toggles"
          :key="`${index}-toggle`"
          button-type="look-like-link"
          role="radio"
          :aria-labelledby="getLabelId(index)"
          :aria-checked="state ? 'true' : 'false'"
          :data-cy="`triple_select--tariff${index}`"
          :clickhandler="() => select(index)"
        >
          <Icon :name="getIconName(state)" :height="`${iconSize}`" />
        </ButtonComponent>

        <div ref="connector" class="connector" />

        <div
          ref="connectorFill"
          class="connector--fill"
          :class="{ 'connector--fill-animation': animate }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { uniqueId } from 'src/lib/helpers'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Icon from '../icon/icon.vue'

export default {
  name: 'TripleSelect',
  components: { ButtonComponent, Icon },
  props: {
    value: {
      type: Number,
      default: 0
    },
    labels: {
      type: Array,
      default: () => []
    }
  },
  emits: ['input'],
  data() {
    return {
      selected: this.value,
      iconSize: 30,
      animate: false,
      markerWidth: 70,
      connectorWidth: 3
    }
  },
  computed: {
    ...mapState({
      theme: state => state.settings.theme
    }),
    toggles() {
      let list = [false, false, false]

      list[this.selected] = true

      return list
    },
    hasValidLabels() {
      return this.labels.length === 3
    },
    groupLableId() {
      return `triple-select-group-label-${uniqueId()}`
    },
    labelIds() {
      return this.labels.map(() => `triple-select-label-${uniqueId()}`)
    }
  },
  mounted() {
    this.renderAll()
    this.animate = true
    window.addEventListener('resize', this.renderAll)
  },
  unmounted() {
    window.removeEventListener('resize', this.renderAll)
  },
  methods: {
    getIconName(value) {
      const state = value ? 'active' : 'inactive'
      const theme = this.theme === 'dark' ? '-dark' : ''

      return `radio${theme}--${state}`
    },
    getLabelId(index) {
      if (!this.hasValidLabels || !this.labelIds[index]) {
        return undefined
      }

      return this.labelIds[index]
    },
    select(index) {
      this.selected = index
      this.$emit('input', index)
      this.updateConnectorFill()
    },
    renderAll() {
      this.renderConnector()
      this.updateConnectorFill()
      if (this.hasValidLabels) {
        this.renderMarker()
      }
    },
    renderConnector() {
      this.$refs.connector.style.left = this.iconSize / 2 + 'px'

      this.$refs.connector.style.width =
        this.$refs.container.getBoundingClientRect().width -
        this.iconSize +
        'px'
    },
    updateConnectorFill() {
      const containerWidth =
        this.$refs.container.getBoundingClientRect().width - this.iconSize

      let width = containerWidth * 0.2

      if (this.selected === 0) {
        this.$refs.connectorFill.style.left = this.iconSize / 2 + 'px'
      } else if (this.selected === 1) {
        width = containerWidth * 0.4

        this.$refs.connectorFill.style.left =
          (this.$refs.container.getBoundingClientRect().width - width) / 2 +
          'px'
      } else {
        this.$refs.connectorFill.style.left =
          containerWidth - width + this.iconSize / 2 + 'px'
      }

      this.$refs.connectorFill.style.width = width + 'px'
    },
    renderMarker() {
      let offset = this.markerWidth / 2

      this.$refs.marker0[0].style.left = this.iconSize / 2 - offset + 'px'

      this.$refs.marker1[0].style.left =
        this.$refs.container.getBoundingClientRect().width / 2 - offset + 'px'

      this.$refs.marker2[0].style.left =
        this.$refs.container.getBoundingClientRect().width -
        offset -
        this.iconSize / 2 +
        'px'
    }
  }
}
</script>

<style lang="scss" scoped>
.triple--wrapper {
  padding: 10px 30px;
  max-width: 450px;
}

.selector--container {
  position: relative;
  display: flex;
  justify-content: space-between;

  > .button-component {
    z-index: 1;
    border-radius: 50%;

    .icon {
      box-shadow: 0 0 10px -2px rgb(0 0 0 / 30%);
      border-radius: 50%;
    }
  }
}

.connector,
.connector--fill {
  position: absolute;
  top: calc(-1.5px + (100% / 2));
  background-color: #b3c3d0;
  height: 3px;
}

.connector--fill {
  background-color: $c_fonic-blue;

  @include fonic-mobile {
    background-color: $c_muted-cyan;
  }
}

.connector--fill-animation {
  transition: all ease-in-out 0.5s;
}

.connector {
  width: 100%;
}

.marker--wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.marker {
  top: 15px;
  position: absolute;
  height: 50px;
  width: 70px;
}

.marker--display {
  position: absolute;
  height: 50px;
  width: calc-rem-min(70px, 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  top: 0;

  > .marker--text {
    font-size: min(1.2rem, 25px);
    line-height: 1.2rem;
    text-align: center;
  }
}

.needle {
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  bottom: -5px;
  left: 30px;
  transition: all ease-in-out 0.3s;
}

.rise {
  top: 0;

  .marker--display {
    color: $c_night-blue;
    background-color: $c_white;
    box-shadow: 0 0 10px -2px rgb(0 0 0 / 30%);
  }

  .needle {
    box-shadow: 3px 3px 6px -3px rgb(0 0 0 / 30%);
    background-color: $c_white;
  }
}

.static--text {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  color: #b3c3d0;
  margin-bottom: 10px;
}
</style>
