<template>
  <div class="tariff--options">
    <Classic />
    <SmartTripleSelectLayout />
  </div>
</template>

<script>
import Classic from './components/classic.vue'
import SmartTripleSelectLayout from './components/smart_triple_select.vue'

export default {
  name: 'PortalHomeTariffPicker',
  components: {
    SmartTripleSelectLayout,
    Classic
  }
}
</script>

<style lang="scss" scoped>
.tariff--options {
  display: flex;

  @include breakpoint($up-to-tablet) {
    flex-direction: column;
  }
}
</style>
