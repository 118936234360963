<template>
  <div class="classic--wrapper">
    <div class="classic--text">
      <ImageComponent
        class="classic--img"
        :src="ClassicImage"
        alt=""
        width="110"
        height="110"
      />
      <div class="classic__title--reverse">
        <h3 class="title">
          {{ $brand.isFonic() ? $brand.name : '' }} Classic<Footnote
            class="allnet--footnote"
            :footnote-id="footnoteId"
          />
        </h3>
        <h4 class="subtitle">Ungebunden und Flexibel</h4>
      </div>
      <span class="ribbon">+ 10 € Startbonus</span>
    </div>

    <ul class="classic__details-list no-break">
      <li class="classic__detail">
        <strong>9 Cent</strong><span>Min./SMS</span>
      </li>
      <li class="classic__detail">
        <strong>24 Cent</strong><span>pro MB</span>
      </li>
      <li class="classic__detail">
        <strong>0 €</strong><span>monatlich</span>
      </li>
    </ul>
    <ButtonComponent
      data-cy="classic--button"
      class="classic--button"
      :clickhandler="() => $router.push(path)"
      :href="$router.resolve(path).href"
      >Zum {{ $brand.isFonic() ? $brand.name : '' }} Classic</ButtonComponent
    >
  </div>
</template>

<script>
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import ClassicImage from '/resources/${BRAND_CODE}-classic-small.webp'
import Footnote from 'src/components/common/footnote/footnote.vue'
import ImageComponent from 'src/components/common/image/image.vue'
export default {
  name: 'PortalHomeClassic',
  components: {
    Footnote,
    ButtonComponent,
    ImageComponent
  },
  data() {
    return {
      footnoteId: 1,
      slug: `${this.$brand.code}-classic`,
      ClassicImage
    }
  },
  computed: {
    path() {
      return {
        name: 'PortalTariffDetails',
        params: { slug: this.slug }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$image-size: min-width 1198px;

.classic--wrapper {
  @include font-normal;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-secondary);
  border-radius: 8px;
  margin: 8px 0;
  padding: 20px 16px;

  @include breakpoint($from-tablet) {
    margin: 8px;
  }

  @include breakpoint($from-desktop) {
    margin-left: 0;
    min-width: 35%;
  }
}

.classic--text {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  flex-grow: 1;

  .title {
    margin-bottom: 24px;

    .allnet--footnote {
      font-size: 75%;

      :deep(.super-footnote) {
        margin-left: 2px;
        top: -10px;
      }
    }
  }

  .subtitle {
    margin-bottom: 10px;
    line-height: 1.2;
    text-align: center;
  }
}

.classic--img {
  margin-bottom: 30px;
}

.classic__details-list {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin: 20px 0;
  flex-grow: 2;
}

.classic__detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ribbon {
  color: $c_night-blue;
  padding: 6px 10px 4px;
  margin-bottom: 30px;
  border-radius: 16px;
  background: $ribbon-color;

  @include breakpoint($up-to-tablet) {
    padding: 12.5px 25px;
    border-radius: 0 25px 25px 0;
    align-self: flex-start;
    margin-left: -16px;
  }
}

.classic--button {
  align-self: stretch;
}

.classic__title--reverse {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
</style>
